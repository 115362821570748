#sidebar {
    position: absolute;
    width: 15vw;
    direction: rtl;
    right: 0;
  }
  #sidebar .pro-sidebar {
    height: 100vh;    
  }
  #sidebar .closemenu {
    color: #ffffff;
    position: absolute;
    left: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
  }
  #sidebar .pro-sidebar {
    width: 100%;
    min-width: 100%;
  }
  #sidebar .pro-sidebar.collapsed {
    width: 5vw;
    min-width: 5vw;
  }
  #sidebar .pro-sidebar-inner {
    background-color: #272835;
    box-shadow: 0.5vmin 0.866vmin 2vmin 0vmin rgba(0, 0, 0, 0.15);
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 5vmin;
    padding: 0 2vmin;
    color: #ffffff;
    font-weight: bold;
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 1vmin;
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #ffffff;
    margin: 1.5vmin 0vmin;
    font-weight: bold;
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #272835;
    color: #ffffff;
    border-radius: 1vmin;
    font-size: 2vmin;
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #ffffff;
    
  }
  #sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #272835 0%, #272835 100%);
  }
  #sidebar .logo {
    padding: 5vmin;
  }
  @media screen and (max-width: 1680px) {
    #sidebar {      
      width: 20vw;      
    }
    #sidebar .pro-sidebar.collapsed {
      width: 7.5vw;
      min-width: 5vw;
    }
    #sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {      
      font-size: 3vmin;
    }
  }

  @media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  }