
*:focus {
  outline: none;
}
.root {
  text-align: center;
  background-color: #ffffff; 
}
body {
  background-color: #1c1d26;
  font-family: 'Open Sans', sans-serif !important;
}
p {
  font-size: 30px;
}
.App-logo {
  height: 40vmin;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.centred_logo {
  width: 75vmin;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


.login_page_logo {  
  width: 100%;
  margin: 0vmin 0vmin 0vmin 0vmin;
  padding-bottom: 5vmin;
}
.login_page_container {  
  color: #000000;
  
  width: auto;
  margin: auto;
  position: absolute;
  top: 40vh;
  left: 50vw;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  align-content: center;
}
.login_page_form {
  align-self: auto;
}
.login_page_label {
  position: relative;
  font-weight:bold;
  color: var(--dark-grey);
  text-align: left;
  margin: 0 auto;
  display: block;
}
.login_page_input {
  font-size: 1.25vmin;
  width: 35vmin;
  margin: 1vmin auto;
  padding: 1vmin 0.5vmin 1vmin 0.5vmin;
  border-radius: 1vmin;
  box-shadow: inset 0 1px 2px rgba(75,75,75, .9), 0px 1px 1px rgba(155,155,155,.85);
  border: 0.1vmin solid var(--dark-grey);
  opacity: 0.7;
}
.login_page_button {
  font-size: 1.25vmin;
  width: 100%;
  height: 4vmin;
  padding: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
  border-radius: 0.4vmin;
  box-shadow: inset 0 1px 2px rgba(0,0,0, .55), 0px 1px 1px rgba(255,255,255,.5);
  border: 0.1em solid var(--dark-grey);
  opacity: .99;
  /* margin: 0.5vmin 0.5vmin 0.5vmin 7.5vmin; */
  background: #4648ff;
  box-shadow: inset 0 0.1vmin 0.1vmin var(--very-light-grey), 0 0.1vmin 0.5vmin var(--light-grey);
  border: 0,1vmin solid #4648ff;
  border-radius: 0.4vmin;
  color: #ffffff;
  cursor: pointer;
}
.login_page_button:hover {
  background: #4648ff;
  width: 100%;
  border: 0.1vmin solid #4648ff;
  border-radius: 0.3vmin;
  box-shadow: inset 0vmin 3vmin 10vmin rgba(0,0,0,.25),0vmin 1vmin 2vmin rgba(255,255,255,.5),inset 0vmin -1vmin 2vmin rgba(255,255,255,.35);
  transition: all 0.40s ease-out;
}
.login_page_p {
  align-self: auto;
}
.login_page_link {
  align-self: auto;
}
.login_page_container h3 {
  color: #ffffff;
  text-align: center;
}


#App {  
  height: 100vh;
}
#page-wrap {
  text-align: center;  
  overflow: auto;
}

.spinner_overlay {
  height: 100%;
  width: 100%;
  /* position: fixed; */
  position: absolute;
  /* background-color: transparent; */
  background-color: #ffffff;
  opacity: 0.8;
}
.spinner_overlay_login {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: transparent;
  /* background-color: #ffffff; */
  opacity: 0.8;
}
.spinner_container {
  height: 10%;
  width: 10%;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  align-content: center;
}

.spinner_container_items {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: #ffffff;
  opacity: 0.8;  
}
